import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import i18n from './../../i18n'
import api from 'services/Api.services'
import { LogOut, LogOutFailure, LogOutSuccess, PasswordAuth } from './LoginViewActions'
import { Action } from 'redux-actions'
import { LOCATION_CHANGE, push, replace } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { ILoginFormFields } from './children/LoginFormFieldNames'
import { sagaWhoAmI } from 'common/auth/AuthSagas'
import LS from 'common/utils/localStorage'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { Login$LoginStatus, Login$Response, WhoAmI$Response } from 'services/api.types'
import { Sign } from '../signing/SigningViewActions'
import { AddDashboardNotification } from '../dashboard/DashboardViewActions'
import { NotificationType } from 'components/ui/notification/Notification'
import { whoAmI } from 'common/auth/AuthActions'
import { USER_CHANGE } from 'common/constants/localstorage-keys'
import * as queryString from 'qs'
import { userDetails } from 'common/auth/AuthSelectors'
import { delay } from 'common/utils/saga'

function* sagaPasswordAuth({ payload }: Action<ILoginFormFields>) {
  const { email, password } = payload
  const loginDetailsRequest = { email, password }

  // Clear notifications
  yield put({ type: String(clearNotification) })

  try {
    const response: Login$Response = yield call(api.auth.login, loginDetailsRequest)

    if (response.code === Login$LoginStatus.APP_SECOND_FACTOR_AUTH_NEEDED) {
      // set dashboard success message
      const successAction = {
        type: String(AddDashboardNotification),
        payload: {
          title: i18n.t('frontendNotifications:success.login'),
          type: NotificationType.SUCCESS,
        },
      }

      // Add notification that login failed
      const failAction = {
        type: String(addNotification),
        payload: {
          description: i18n.t('frontendNotifications:error.login'),
          type: NotificationType.ERROR,
        },
      }

      // Add notification that login failed
      const cancelAction = {
        type: String(addNotification),
        payload: {
          description: i18n.t('frontendNotifications:error.login-cancel'),
          type: NotificationType.ERROR,
        },
      }

      // redirect to signing
      yield put({
        type: String(Sign),
        payload: {
          signActionId: response.signActionId,
          signedAction: successAction,
          cancelledAction: cancelAction,
          signedUrl: paths.dashboard.root,
          expiredAction: failAction,
          deniedAction: failAction,
          expiredUrl: paths.login,
          deniedUrl: paths.login,
          cancelledUrl: paths.login,
          usersEmail: email,
          title: i18n.t('signingView:login.title'),
          successTitle: i18n.t('signingView:login.successTitle'),
        },
      })
    } else {
      yield call(sagaWhoAmI)
      const whoAmIResponse: WhoAmI$Response = yield select(userDetails)
      if (whoAmIResponse.ledgerCreation) {
        yield put(push(paths.onboarding.root))
      } else {
        yield put(push(paths.dashboard.root))
      }
    }
  } catch (e) {
    // Add notification when login failed
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaLogOut(action: Action<INotification>) {
  LS.clear()

  const notifications: INotification[] = action.payload ? [action.payload] : []

  try {
    yield call(api.auth.logout)
    yield put({ type: String(whoAmI) })
    yield put({ type: String(LogOutSuccess) })
  } catch (e) {
    console.log('logout failed:', e)
    yield put({ type: String(LogOutFailure) })
  } finally {
    yield put(replace(paths.login, { notifications }))
    LS.put(USER_CHANGE, '')
  }
}

function* sagaLocationChange() {
  try {
    const pathObject: any = queryString.parse(window.location.search)
    if (window.location.pathname === paths.login && pathObject.redirectMessage) {
      yield call(delay, 1000)
      yield put({
        type: String(addNotification),
        payload: {
          description: i18n.t(`frontendNotifications:info.login-redirect-message.${pathObject.redirectMessage}`),
          type: NotificationType.INFO,
        },
      })
    }
  } catch (e) {
    console.log('sagaLocationChange failed:', e)
  }
}

export default function* loginViewSagas() {
  yield all([
    takeLatest(String(PasswordAuth), sagaPasswordAuth),
    takeLatest(String(LogOut), sagaLogOut),
    takeLatest(String(LOCATION_CHANGE), sagaLocationChange),
  ])
}
