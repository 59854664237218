import { Action } from 'redux-actions'
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import { push, replace } from 'connected-react-router'
import * as _ from 'lodash'
import * as stringify from 'json-stringify-safe'
import moment from 'moment'

import { paths } from 'common/router/routePaths'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import {
  AcceptTransfer$Response,
  AccountId,
  AccountStockListDto,
  CancelTransfer$Request,
  CancelTransfer$Response,
  CreateBulkHolder$Request,
  CreateBulkHolder$Request$Holder,
  CreateBulkHolder$Response,
  CreateBulkHolder$Response$Holder,
  CreateBulkIssuance$Request$CurrencyType,
  CreateBulkTransfer$Request,
  CreateBulkTransfer$Response,
  CreateHolderTransfer$Request,
  CreateHolderTransfer$Response,
  CreateRetirementTransfer$Request,
  CreateRetirementTransfer$Response,
  CreateTransfer$Request,
  CreateTransfer$Response,
  CurrencyType,
  DeclineTransfer$Response,
  GetAccountStockList$Response,
  GetDashboard$Response$Progress,
  GetStockList$Response,
  GetTransferDetails$Response,
  GetTransferList$Response,
  HolderDto,
  HolderType,
  StockListDto,
  TransferId,
  TransferListDto,
  TransferStatusType,
} from 'services/api.types'
import api from 'services/Api.services'
import {
  AcceptTransfer,
  CancelTransfer,
  ClearCancelledTransferDraft,
  ClearHistoricLedgerImportDraft,
  ClearTransferDraft,
  CreateAdminTransfer,
  CreateBulkTransfer,
  CreateBulkTransferFailure,
  CreateBulkTransferSuccess,
  CreateHolderTransfer,
  CreateRetirementTransfer,
  DeclineTransfer,
  DeleteHistoricTransferDraft,
  EditTransfer,
  GetCancelledTransferDraft,
  GetCancelledTransferDraftFailure,
  GetCancelledTransferDraftSuccess,
  GetTransferDraft,
  GetTransferDraftFailure,
  GetTransferDraftSuccess,
  GetHistoricTransferDetails,
  GetHistoricTransferDetailsFail,
  GetHistoricTransferDetailsSuccess,
  GetHistoricLedgerImportDraft,
  GetHistoricLedgerImportDraftFailure,
  GetHistoricLedgerImportDraftSuccess,
  GetTransferDetails,
  GetTransferDetailsFail,
  GetTransferDetailsSuccess,
  GetTransferList,
  GetTransferListFail,
  GetTransferListSuccess,
  GetTransferorStockList,
  GetTransferorStockListFail,
  GetTransferorStockListSuccess,
  SaveCancelledTransferDraft,
  SaveHistoricTransferDraft,
  SaveHistoricTransferDraftFailure,
  SaveHistoricTransferDraftSuccess,
  SaveTransferDraft,
  GetBulkImportDraft,
  GetBulkImportDraftSuccess,
  GetBulkImportDraftFailure,
  SaveBulkImportDraft,
  ClearBulkImportDraft,
  CreateBulkHolder,
  CreateBulkHolderSuccess,
  CreateBulkHolderFailure,
  SetLoaded,
  SetLoading,
  SaveHistoricLedgerImportDraftFailure,
  SaveHistoricLedgerImportDraftSuccess,
  SaveHistoricLedgerImportDraft,
  ClearTransferDraftSuccess,
  ClearTransferDraftFailure,
  BulkImportTransfer,
  SaveBulkHistoricTransferDraft,
  SaveBulkHistoricTransferDraftSuccess,
  SaveBulkHistoricTransferDraftFailure,
} from './TransfersViewActions'
import { getCreateTransferLoading, getTreasuryStockAccount } from './TransfersViewSelectors'
import { Sign } from '../signing/SigningViewActions'
import { AddDashboardNotification } from '../dashboard/DashboardViewActions'
import { NotificationType } from 'components/ui/notification/Notification'
import { INewTransferFormFields } from './children/new-transfer-form/NewTransferFormFieldNames'
import {
  IBulkImportDraft,
  IBulkImportPayload,
  IBulkTransferDraft,
} from './children/bulk-import-form/BulkImportFormFieldNames'
import { ICancelledTransferDraft } from './children/cancel-transfer-form/CancelTransferFormFieldNames'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { IHistoricTransferDraft } from './children/new-transfer-historic-form/NewTransferHistoricFormFieldNames'
import { GetStockHoldersSuccess } from 'views/holder-list/HolderListViewActions'
import {
  getAccountStockListFromHistoricDraft,
  getHolderSharesFromHistoricDraft,
  getTransferDetailsFromHistoricDraft,
} from './children/new-transfer-historic-view/NewTransferHistoricView.helpers'
import { getIsHistoricImportMode } from 'common/store/common/commonSelector'
import DraftStorage from 'common/utils/draftStorage'
import { DEFAULT_HISTORIC_IMPORT_DRAFT, IHistoricLedgerImportDraft } from './TransfersViewReducer'
import { matcherEngine } from 'common/csv/matcherEngine'

/*
    Get Transfers List
 */
function* sagaGetTransferList() {
  try {
    const transferList: GetTransferList$Response = yield call(api.ledger.getTransferList)
    yield put({ type: String(GetTransferListSuccess), payload: transferList.transfers })
  } catch (e: any) {
    yield put({ type: String(GetTransferListFail) })
    console.log('Error during GetTransferList', e)
  }
}

/* Get Transfer Details */
function* sagaGetTransferDetails(action: Action<{ id: string; pendingTransfer: boolean }>) {
  try {
    const historicImportMode: boolean = yield select(getIsHistoricImportMode)
    let transferDetails: GetTransferDetails$Response

    if (historicImportMode) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }
      transferDetails = getTransferDetailsFromHistoricDraft(action.payload.id, draft)
    } else {
      transferDetails = yield call(api.ledger.getTransferDetails, action.payload)
    }
    yield put({ type: String(GetTransferDetailsSuccess), payload: transferDetails })
  } catch (e: any) {
    yield put({ type: String(GetTransferDetailsFail) })
    console.log('Error during GetTransferDetails', e)
  }
}

function* sagaGetHistoricTransferDetails(action: Action<{ id: string }>) {
  try {
    const draftResponse: IHistoricLedgerImportDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
    )
    const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

    const transferDetails: IHistoricTransferDraft = draft.transfers.find(t => t.id === action.payload.id)
    yield put({ type: String(GetHistoricTransferDetailsSuccess), payload: transferDetails })
  } catch (e: any) {
    yield put({ type: String(GetHistoricTransferDetailsFail) })
    console.log('Error during GetTransferDetails', e)
  }
}

/* Get TransferorStockList  */
function* sagaGetTransferorStockList(action: Action<AccountId>) {
  try {
    const historicImportMode: GetDashboard$Response$Progress = yield select(getIsHistoricImportMode)
    const { list: stockList }: GetStockList$Response = yield call(api.ledger.getStockList)
    let accountStockList: GetAccountStockList$Response

    if (historicImportMode) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft = draftResponse ? draftResponse : DEFAULT_HISTORIC_IMPORT_DRAFT
      accountStockList = getAccountStockListFromHistoricDraft(action.payload, draft, stockList)
    } else {
      accountStockList = yield call(api.ledger.getAccountStockList, {
        id: action.payload,
      })
    }

    if (accountStockList.account) {
      yield put({ type: String(GetTransferorStockListSuccess), payload: accountStockList.account.stocks })
    } else {
      yield put({ type: String(GetTransferorStockListFail) })
    }
  } catch (e: any) {
    yield put({ type: String(GetTransferorStockListFail) })
    console.log('Error during GetTransferorStockList', e)
  }
}

/* Create transfer */
function* sagaCreateAdminTransfer(
  action: Action<{ transfer: CreateTransfer$Request; meta: { from: string; to: string } }>,
) {
  // Is Create transfer request pending?
  const isLoading: boolean = yield select(getCreateTransferLoading)
  if (isLoading) {
    return
  }

  // Clear notifications
  yield put({ type: String(clearNotification) })

  try {
    // Set loading true
    yield put({ type: String(SetLoading) })

    const response: CreateTransfer$Response = yield call(api.ledger.createTransfer, action.payload.transfer)

    // set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.transfer-success', {
          ns: 'frontendNotifications',
          from: action.payload.meta.from,
          to: action.payload.meta.to,
        }),
      },
    }
    // Set cleanAction
    const cleanAction = { type: String(ClearTransferDraft) }
    // redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        cleanAction,
      },
    })

    // Request done, set loaded
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })

    // Request failed, set loaded
    yield put({ type: String(SetLoaded) })
  }
}

/* Create transfer */
function* sagaCreateHolderTransfer(
  action: Action<{ transfer: CreateHolderTransfer$Request; meta: { from: string; to: string } }>,
) {
  // Is Create transfer request pending?
  const isLoading: boolean = yield select(getCreateTransferLoading)
  if (isLoading) {
    return
  }

  // Clear notifications
  yield put({ type: String(clearNotification) })

  try {
    // Set loading true
    yield put({ type: String(SetLoading) })

    const response: CreateHolderTransfer$Response = yield call(api.ledger.createHolderTransfer, action.payload.transfer)

    // set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.transfer-message-sent', {
          ns: 'frontendNotifications',
          to: action.payload.meta.to,
        }),
      },
    }
    // Set cleanAction
    const cleanAction = { type: String(ClearTransferDraft) }
    // redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        cleanAction,
      },
    })

    // Request done, set loaded
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })

    // Request failed, set loaded
    yield put({ type: String(SetLoaded) })
  }
}

/* Create retirement transfer */
function* sagaCreateRetirementTransfer(
  action: Action<{ transfer: CreateRetirementTransfer$Request; meta: { from: string; to: string } }>,
) {
  // Is Create transfer request pending?
  const isLoading: boolean = yield select(getCreateTransferLoading)
  if (isLoading) {
    return
  }

  // Clear notifications
  yield put({ type: String(clearNotification) })

  try {
    // Set loading true
    yield put({ type: String(SetLoading) })

    const response: CreateRetirementTransfer$Response = yield call(
      api.ledger.createRetirementTransfer,
      action.payload.transfer,
    )

    // set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.transfer-success', {
          ns: 'frontendNotifications',
          from: action.payload.meta.from,
          to: action.payload.meta.to,
        }),
      },
    }
    // Set cleanAction
    const cleanAction = { type: String(ClearTransferDraft) }
    // redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        cleanAction,
      },
    })

    // Request done, set loaded
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })

    // Request failed, set loaded
    yield put({ type: String(SetLoaded) })
  }
}

function* sagaCancelTransfer(action: Action<{ cancelledTransfer: CancelTransfer$Request }>) {
  try {
    yield put({ type: String(SetLoading) })
    const response: CancelTransfer$Response = yield call(api.ledger.cancelTransfer, action.payload.cancelledTransfer)
    const cancelledUrl = {
      pathname: paths.transfers.cancelReport,
    }
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedUrl: paths.transfers.root,
        cancelledUrl,
        deniedUrl: cancelledUrl,
      },
    })
    window.scrollTo(0, 0)
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    window.scrollTo(0, 0)
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaClearTransferDraft(action: Action<{ toDashboard: boolean }>) {
  try {
    if (action.payload && action.payload.toDashboard) {
      yield put(push(paths.dashboard.root))
    } else {
      yield put(push(paths.transfers.root))
    }
    yield call(DraftStorage.clear, DraftStorageEnum.NEW_TRANSFER)
    yield put({ type: String(ClearTransferDraftSuccess), payload: action.payload })
  } catch (e: any) {
    console.log('sagaClearTransferDraft error', e)
    yield put({ type: String(ClearTransferDraftFailure) })
  }
}

function* sagaSaveNewTransferDraft(action: Action<INewTransferFormFields>) {
  try {
    yield put(push(paths.transfers.report))
    yield call(DraftStorage.save, DraftStorageEnum.NEW_TRANSFER, stringify(action.payload))
  } catch (e: any) {
    console.log('sagaSaveNewTransferDraft error', e)
  }
}

function* sagaGetBulkImportDraft() {
  try {
    const transfer: IBulkImportDraft[] = yield call(DraftStorage.get, DraftStorageEnum.BULK_IMPORT)
    yield put(GetBulkImportDraftSuccess(transfer))
  } catch (e: any) {
    yield put(GetBulkImportDraftFailure())
    console.log('sagaGetBulkImportDraft error', e)
  }
}

function* sagaSaveBulkImportDraft(action: Action<IBulkImportDraft[]>) {
  try {
    yield put(push(paths.transfers.bulkImportReview))
    yield call(DraftStorage.save, DraftStorageEnum.BULK_IMPORT, JSON.stringify(action.payload))
  } catch (e: any) {
    console.log('sagaSaveBulkImportDraft error', e)
  }
}

function* sagaClearBulkImportDraft() {
  try {
    yield call(DraftStorage.clear, DraftStorageEnum.BULK_IMPORT)
    yield put(GetBulkImportDraft())
  } catch (e: any) {
    console.log('sagaClearBulkImportDraft error', e)
  }
}

function* sagaCreateBulkHolder(action: Action<CreateBulkHolder$Request>) {
  yield put({ type: String(clearNotification) })

  try {
    yield put({ type: String(SetLoading) })

    const response: CreateBulkHolder$Response = yield call(api.ledger.createBulkHolder, action.payload)

    yield put({
      type: String(CreateBulkHolderSuccess),
      payload: {
        data: action.payload.holders,
        ids: response.holders,
      },
    })
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
    yield put({ type: String(CreateBulkHolderFailure) })
  }
}

function* sagaBulkImportTransfer(action: Action<IBulkImportPayload>) {
  const holderAction: Action<{ data: CreateBulkHolder$Request$Holder[]; ids: CreateBulkHolder$Response$Holder[] }> =
    yield take(CreateBulkHolderSuccess)
  const treasuryStockAccount: string = yield select(getTreasuryStockAccount)
  const createTransferPayload = matcherEngine({
    ...action.payload,
    holders: holderAction.payload,
    treasuryStockAccount,
  })
  yield put({ type: String(SaveBulkHistoricTransferDraft), payload: createTransferPayload })
}

function* sagaGetTransferDraft() {
  try {
    const transfer: INewTransferFormFields = yield call(DraftStorage.get, DraftStorageEnum.NEW_TRANSFER)
    yield put({ type: String(GetTransferDraftSuccess), payload: transfer })
  } catch (e: any) {
    yield put({ type: String(GetTransferDraftFailure) })
  }
}

function* sagaGetCancelledTransferDraft() {
  try {
    const transfer: ICancelledTransferDraft = yield call(DraftStorage.get, DraftStorageEnum.CANCELLED_TRANSFER)
    yield put(GetCancelledTransferDraftSuccess(transfer))
  } catch (e: any) {
    yield put(GetCancelledTransferDraftFailure())
  }
}

function* sagaClearCancelledTransferDraft(action: Action<{ toDashboard: boolean }>) {
  if (action.payload && action.payload.toDashboard) {
    yield put(push(paths.dashboard.root))
  } else {
    yield put(push(paths.transfers.root))
  }
  yield call(DraftStorage.clear, DraftStorageEnum.CANCELLED_TRANSFER)
}

function* sagaSaveCancelledTransferDraft(action: Action<ICancelledTransferDraft>) {
  try {
    yield put(push(paths.transfers.cancelReport))
    yield call(DraftStorage.save, DraftStorageEnum.CANCELLED_TRANSFER, stringify(action.payload))
  } catch (e: any) {
    console.log('sagaSaveCancelledTransferDraft', e)
  }
}

function* sagaEditTransfer() {
  yield put(replace(paths.transfers.new))
}

function* sagaAcceptTransfer(action: Action<TransferId>) {
  try {
    const response: AcceptTransfer$Response = yield call(api.ledger.acceptTransfer, { id: action.payload })
    const successAction = {
      type: String(addNotification),
      payload: {
        description: getI18n().t('success.transfer-accepted', { ns: 'frontendNotifications' }),
        type: NotificationType.SUCCESS,
      },
    }
    const failAction = {
      type: String(addNotification),
      payload: {
        description: getI18n().t('error.transfer-accepted', { ns: 'frontendNotifications' }),
        type: NotificationType.ERROR,
      },
    }
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        signedUrl: paths.transfers.root,
        expiredAction: failAction,
        cancelledUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        expiredUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        deniedUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        title: getI18n().t('transfer-accept.title', { ns: 'signingView' }),
        successTitle: getI18n().t('transfer-accept.successTitle', { ns: 'signingView' }),
      },
    })
    yield put(push(paths.transfers.root, {}))
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaDeclineTransfer(action: Action<TransferId>) {
  try {
    const response: DeclineTransfer$Response = yield call(api.ledger.declineTransfer, { id: action.payload })
    const successAction = {
      type: String(addNotification),
      payload: {
        text: getI18n().t('success.transfer-declined', { ns: 'frontendNotifications' }),
        type: NotificationType.SUCCESS,
      },
    }
    const failAction = {
      type: String(addNotification),
      payload: {
        text: getI18n().t('error.transfer-declined', { ns: 'frontendNotifications' }),
        type: NotificationType.ERROR,
      },
    }
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        signedUrl: paths.transfers.root,
        expiredAction: failAction,
        deniedAction: failAction,
        cancelledUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        expiredUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        deniedUrl: {
          pathname: paths.transfers.details,
          state: { id: action.payload, pendingTransfer: true },
        },
        title: getI18n().t('transfer-decline.title', { ns: 'signingView' }),
        successTitle: getI18n().t('transfer-decline.successTitle', { ns: 'signingView' }),
      },
    })
    yield put(push(paths.transfers.root, {}))
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export function* sagaGetHistoricLedgerImportDraft() {
  try {
    yield put({ type: String(SetLoading) })
    const { list: stocks }: GetStockList$Response = yield call(api.ledger.getStockList)
    const response: IHistoricLedgerImportDraft = yield call(DraftStorage.get, DraftStorageEnum.HISTORIC_LEDGER_IMPORT)
    const draftJson = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...response }
    const { holders, transfers, data } = draftJson

    // Transform and set transfers & holders list data
    const holderAccounts: AccountStockListDto[] = []
    const transfersRemapped = transfers.map(transfer => {
      const hasFromHolderAccountMapped = holderAccounts.find(
        ha => ha.accountId === transfer.from.value && ha.stockClass.id === transfer.stockClassId.value,
      )
      const hasToHolderAccountMapped = holderAccounts.find(
        ha => ha.accountId === transfer.to.value && ha.stockClass.id === transfer.stockClassId.value,
      )
      let stockData = stocks?.find(s => s.id === transfer.stockClassId.value)

      if (!stockData) {
        stocks?.forEach(s => {
          const serieMatch = s?.series?.find(ss => ss.id === transfer.stockClassId.value)
          if (serieMatch) {
            stockData = { ...s, ...serieMatch }
          }
        })
      }

      if (stockData && !hasFromHolderAccountMapped) {
        const holderData = holders.find(h => h?.data?.accountId === transfer.from.value)

        if (holderData) {
          holderAccounts.push({
            accountId: holderData.data.accountId,
            accountName:
              holderData.type === HolderType.LEGAL_ENTITY ? holderData.data.name : holderData.data.calculatedName,
            holderCredentials: {
              holderId: holderData.data.holderId,
              emails: [],
              phoneNumbers: [],
            },
            holders: [
              {
                address: holderData.data.address,
                confirmed: false,
                email: holderData.data.email,
                id: holderData.data.holderId,
                type: holderData.type,
                name: holderData.data.name,
                lastName: '',
                ledgerId: '',
                middleName: '',
                nameSuffix: '',
              },
            ] as HolderDto[],
            numberOfShares: getHolderSharesFromHistoricDraft(transfer.stockClassId.value, holderData.data.accountId, {
              holders,
              transfers,
              data,
            }),
            stockClass: {
              active: stockData.active,
              createdBy: stockData.createdBy,
              id: stockData.id,
              parValue: stockData.parValue,
              restrictions: stockData.restrictions,
              stockName: stockData.stockName,
              totalNumberOfShares: stockData.totalNumberOfShares,
              type: stockData.type,
              voting: stockData.voting,
              votingRights: stockData.votingRights,
            },
          } as AccountStockListDto)
        }
      }

      if (stockData && !hasToHolderAccountMapped) {
        const holderData = holders.find(h => h?.data?.accountId === transfer.to.value)

        if (holderData) {
          holderAccounts.push({
            accountId: holderData.data.accountId,
            accountName:
              holderData.type === HolderType.LEGAL_ENTITY ? holderData.data.name : holderData.data.calculatedName,
            holderCredentials: {
              holderId: holderData.data.holderId,
              emails: [],
              phoneNumbers: [],
            },
            holders: [
              {
                address: holderData.data.address,
                confirmed: false,
                email: holderData.data.email,
                id: holderData.data.holderId,
                type: holderData.type,
                name: holderData.data.name,
                lastName: '',
                ledgerId: '',
                middleName: '',
                nameSuffix: '',
              },
            ] as HolderDto[],
            numberOfShares: getHolderSharesFromHistoricDraft(transfer.stockClassId.value, holderData.data.accountId, {
              holders,
              transfers,
              data,
            }),
            stockClass: {
              active: stockData.active,
              createdBy: stockData.createdBy,
              id: stockData.id,
              parValue: stockData.parValue,
              restrictions: stockData.restrictions,
              stockName: stockData.stockName,
              totalNumberOfShares: stockData.totalNumberOfShares,
              type: stockData.type,
              voting: stockData.voting,
              votingRights: stockData.votingRights,
            },
          } as AccountStockListDto)
        }
      }

      return {
        id: transfer.id,
        stockClass: transfer.stockClassId.value,
        amountPaid: transfer.amountPaid,
        currencyType: CurrencyType.FIAT_MONEY,
        currency: undefined,
        createdAt: new Date(transfer.dateOfTransfer).getTime(),
        documents: transfer.documents,
        from: {
          emails: [],
          phoneNumbers: [],
          name: '',
        },
        fromAccountName: transfer.from.label,
        fromAccount: transfer.from.value,
        issuance: transfer.issuance,
        numberOfShares: transfer.numberOfShares,
        pendingTransfer: true,
        status: TransferStatusType.PENDING,
        stockClassName: transfer.stockClassId.label,
        to: {
          emails: [],
          phoneNumbers: [],
          name: '',
        },
        toAccountName: transfer.to.label,
        toAccount: transfer.to.value,
        certificatedTransfer: transfer.certificatedTransfer,
      } as TransferListDto
    })
    yield put(GetTransferListSuccess(transfersRemapped))

    yield put(GetStockHoldersSuccess(holderAccounts))
    yield put(GetHistoricLedgerImportDraftSuccess({ data, transfers: transfersRemapped, holders: holderAccounts }))
  } catch (e: any) {
    yield put(GetHistoricLedgerImportDraftFailure())
    console.log('GetHistoricLedgerImportDraftFailure', e)
  } finally {
    yield put({ type: String(SetLoaded) })
  }
}

/** Used for: single transfer draft saving */
export function* sagaSaveHistoricTransferDraft(action: Action<IHistoricTransferDraft>) {
  try {
    const payload = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...action.payload }
    const draftResponse: IHistoricLedgerImportDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
    )
    const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

    const existingTransfer = draft.transfers.find(t => t.id === payload.id)

    if (existingTransfer) {
      draft.transfers.forEach((t, i) => {
        if (t.id === payload.id) {
          draft.transfers[i] = action.payload
        }
        return
      })
    } else {
      draft.transfers[draft.transfers.length] = payload
    }

    const existingHolder = draft.holders.find(holder => holder.data?.accountId === payload.to.value)
    if (!existingHolder) {
      draft.holders[draft.holders.length] = payload.addedHolder
    }

    const dataString = stringify(draft)
    yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, dataString)
    yield put(SaveHistoricTransferDraftSuccess())
    yield put(GetHistoricLedgerImportDraft())
    yield put(push(paths.transfers.root))
  } catch (e: any) {
    yield put(SaveHistoricTransferDraftFailure())
    console.log(e)
  }
}

/** Used for: bulk transfer draft saving */
export function* sagaSaveBulkHistoricTransferDraft(action: Action<IHistoricTransferDraft[]>) {
  try {
    const draftResponse: IHistoricLedgerImportDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
    )
    const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

    for (const draftPayload of action.payload) {
      const payload = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftPayload }

      const existingTransfer = draft.transfers.find(t => t.id === payload.id)

      if (existingTransfer) {
        draft.transfers.forEach((t, i) => {
          if (t.id === payload.id) {
            draft.transfers[i] = draftPayload
          }
          return
        })
      } else {
        draft.transfers[draft.transfers.length] = payload
      }

      const existingHolder = draft.holders.find(holder => holder?.data?.accountId === payload.to?.value)
      if (!existingHolder && payload.addedHolder) {
        draft.holders[draft.holders.length] = payload.addedHolder
      }
    }

    const dataString = stringify(draft)
    yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, dataString)
    yield put(SaveBulkHistoricTransferDraftSuccess())
    yield put(GetHistoricLedgerImportDraft())
    yield put(ClearBulkImportDraft())
    yield put(push(paths.transfers.root))
    yield put({
      type: String(addNotification),
      payload: {
        description: getI18n().t('success.bulk-import-completed', {
          ns: 'frontendNotifications',
        }),
        type: NotificationType.SUCCESS,
      },
    })
  } catch (e: any) {
    yield put(SaveBulkHistoricTransferDraftFailure())
    console.log(e)
  }
}

/** Used for: historic ledger draft generic form data saving */
export function* sagaSaveHistoricLedgerImportDraft(action: Action<IHistoricLedgerImportDraft>) {
  try {
    const { payload } = action
    const draftResponse: IHistoricLedgerImportDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
    )
    const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

    if (!_.isEqual(draft.data, payload.data)) {
      draft.data = payload.data
    }

    const dataString = stringify(draft)

    yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, dataString)
    yield put(SaveHistoricLedgerImportDraftSuccess())
  } catch (e: any) {
    yield put(SaveHistoricLedgerImportDraftFailure())
    console.log(e)
  }
}

/* Deletes single historic transfer record from list */
export function* sagaDeleteHistoricTransferDraft(action: Action<{ id: string }>) {
  try {
    const draftResponse: IHistoricLedgerImportDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
    )
    const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

    draft.transfers = draft.transfers.filter(t => t.id !== action.payload.id)
    const data = stringify({ transfers: draft.transfers, holders: draft.holders, data: draft.data })

    yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, data)
    yield put(GetHistoricLedgerImportDraft())
    yield put(push(paths.transfers.root))
  } catch (e: any) {
    console.log(e)
  }
}

/** Currently used for historic ledger import only (messages etc. are specific) */
export function* sagaCreateBulkTransfer(action: Action<CreateBulkTransfer$Request>) {
  yield put({ type: String(clearNotification) })

  try {
    yield put({ type: String(SetLoading) })

    const response: CreateBulkTransfer$Response = yield call(api.ledger.createBulkTransfer, action.payload)
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.historic-ledger-import-completed', {
          ns: 'frontendNotifications',
        }),
      },
    }
    const cleanAction = { type: String(ClearHistoricLedgerImportDraft) }

    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        cleanAction,
      },
    })

    yield put({ type: String(CreateBulkTransferSuccess) })
  } catch (e: any) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
    yield put({ type: String(CreateBulkTransferFailure) })
  }
}

/** Resets historic transfer draft */
export function* sagaClearHistoricLedgerImportDraft() {
  try {
    yield call(DraftStorage.clear, DraftStorageEnum.HISTORIC_LEDGER_IMPORT)
  } catch (e) {
    console.log('sagaClearHistoricLedgerImportDraft failed', e)
  }
}

export default function* transfersViewSagas() {
  yield all([
    takeEvery(String(GetTransferList), sagaGetTransferList),
    takeEvery(String(SaveTransferDraft), sagaSaveNewTransferDraft),
    takeEvery(String(GetBulkImportDraft), sagaGetBulkImportDraft),
    takeEvery(String(SaveBulkImportDraft), sagaSaveBulkImportDraft),
    takeEvery(String(ClearBulkImportDraft), sagaClearBulkImportDraft),
    takeEvery(String(CreateBulkHolder), sagaCreateBulkHolder),
    takeEvery(String(BulkImportTransfer), sagaBulkImportTransfer),
    takeEvery(String(ClearTransferDraft), sagaClearTransferDraft),
    takeEvery(String(SaveCancelledTransferDraft), sagaSaveCancelledTransferDraft),
    takeEvery(String(ClearCancelledTransferDraft), sagaClearCancelledTransferDraft),
    takeEvery(String(CreateAdminTransfer), sagaCreateAdminTransfer),
    takeEvery(String(CreateHolderTransfer), sagaCreateHolderTransfer),
    takeEvery(String(CreateRetirementTransfer), sagaCreateRetirementTransfer),
    takeEvery(String(CancelTransfer), sagaCancelTransfer),
    takeEvery(String(EditTransfer), sagaEditTransfer),
    takeEvery(String(GetTransferDetails), sagaGetTransferDetails),
    takeEvery(String(GetHistoricTransferDetails), sagaGetHistoricTransferDetails),
    takeEvery(String(AcceptTransfer), sagaAcceptTransfer),
    takeEvery(String(DeclineTransfer), sagaDeclineTransfer),
    takeEvery(String(GetTransferorStockList), sagaGetTransferorStockList),
    takeEvery(String(GetHistoricLedgerImportDraft), sagaGetHistoricLedgerImportDraft),
    takeEvery(String(SaveHistoricLedgerImportDraft), sagaSaveHistoricLedgerImportDraft),
    takeEvery(String(SaveHistoricTransferDraft), sagaSaveHistoricTransferDraft),
    takeEvery(String(SaveBulkHistoricTransferDraft), sagaSaveBulkHistoricTransferDraft),
    takeEvery(String(DeleteHistoricTransferDraft), sagaDeleteHistoricTransferDraft),
    takeEvery(String(ClearHistoricLedgerImportDraft), sagaClearHistoricLedgerImportDraft),
    takeEvery(String(CreateBulkTransfer), sagaCreateBulkTransfer),
    takeEvery(String(GetTransferDraft), sagaGetTransferDraft),
    takeEvery(String(GetCancelledTransferDraft), sagaGetCancelledTransferDraft),
  ])
}
