import { createAction } from 'redux-actions'
import { IHistoricLedgerImportRemappedDraft } from './TransfersViewReducer'

const ns = 'transfers/'

export const GetTransferList = createAction(`${ns}GET_TRANSFER_LIST`)
export const GetTransferListSuccess = createAction(`${ns}GET_TRANSFER_LIST_SUCCESS`)
export const GetTransferListFail = createAction(`${ns}GET_TRANSFER_LIST_FAIL`)

export const GetTransferDetails = createAction(`${ns}GET_TRANSFER_DETAILS`)
export const GetTransferDetailsSuccess = createAction(`${ns}GET_TRANSFER_DETAILS_SUCCESS`)
export const GetTransferDetailsFail = createAction(`${ns}GET_TRANSFER_DETAILS_FAIL`)

export const GetHistoricTransferDetails = createAction(`${ns}GET_HISTORIC_TRANSFER_DETAILS`)
export const GetHistoricTransferDetailsSuccess = createAction(`${ns}GET_HISTORIC_TRANSFER_DETAILS_SUCCESS`)
export const GetHistoricTransferDetailsFail = createAction(`${ns}GET_HISTORIC_TRANSFER_DETAILS_FAIL`)

/* Set Transfer List filters */
export const SetFilters = createAction(`${ns}SET_FILTERS`)

/* Get Selected transferor stockList */
export const GetTransferorStockList = createAction(`${ns}GET_TRANSFEROR_STOCK_LIST`)
export const GetTransferorStockListSuccess = createAction(`${ns}GET_TRANSFEROR_STOCK_LIST_SUCCESS`)
export const GetTransferorStockListFail = createAction(`${ns}GET_TRANSFEROR_STOCK_LIST_FAIL`)

export const GetStockList = createAction(`${ns}GET_STOCK_LIST`)
export const GetStockListSuccess = createAction(`${ns}GET_STOCK_LIST_SUCCESS`)
export const GetStockListFail = createAction(`${ns}GET_STOCK_LIST_FAIL`)

export const GetTransferDraft = createAction(`${ns}GET_TRANSFER_DRAFT`)
export const GetTransferDraftSuccess = createAction(`${ns}GET_TRANSFER_DRAFT_SUCCESS`)
export const GetTransferDraftFailure = createAction(`${ns}GET_TRANSFER_DRAFT_FAILURE`)

export const GetCancelledTransferDraft = createAction(`${ns}GET_CANCELLED_TRANSFER_DRAFT`)
export const GetCancelledTransferDraftSuccess = createAction(`${ns}GET_CANCELLED_TRANSFER_DRAFT_SUCCESS`)
export const GetCancelledTransferDraftFailure = createAction(`${ns}GET_CANCELLED_TRANSFER_DRAFT_FAILURE`)

export const SaveHistoricLedgerImportDraft = createAction(
  `${ns}SAVE_HISTORIC_LEDGER_IMPORT_DRAFT`,
  (payload: any) => payload,
)
export const SaveHistoricLedgerImportDraftSuccess = createAction(`${ns}SAVE_HISTORIC_LEDGER_IMPORT_DRAFT_SUCCESS`)
export const SaveHistoricLedgerImportDraftFailure = createAction(`${ns}SAVE_HISTORIC_LEDGER_IMPORT_DRAFT_FAILURE`)

export const GetHistoricLedgerImportDraft = createAction(`${ns}GET_HISTORIC_LEDGER_IMPORT_DRAFT`)
export const GetHistoricLedgerImportDraftSuccess = createAction(
  `${ns}GET_HISTORIC_LEDGER_IMPORT_DRAFT_SUCCESS`,
  (payload: IHistoricLedgerImportRemappedDraft) => payload,
)
export const GetHistoricLedgerImportDraftFailure = createAction(`${ns}GET_HISTORIC_LEDGER_IMPORT_DRAFT_FAILURE`)

export const SaveHistoricTransferDraft = createAction(`${ns}SAVE_HISTORIC_TRANSFER_DRAFT`, (payload: any) => payload)
export const SaveHistoricTransferDraftSuccess = createAction(`${ns}SAVE_HISTORIC_TRANSFER_DRAFT_SUCCESS`)
export const SaveHistoricTransferDraftFailure = createAction(`${ns}SAVE_HISTORIC_TRANSFER_DRAFT_FAILURE`)

export const SaveBulkHistoricTransferDraft = createAction(
  `${ns}SAVE_BULK_HISTORIC_TRANSFER_DRAFT`,
  (payload: any) => payload,
)
export const SaveBulkHistoricTransferDraftSuccess = createAction(`${ns}SAVE_BULK_HISTORIC_TRANSFER_DRAFT_SUCCESS`)
export const SaveBulkHistoricTransferDraftFailure = createAction(`${ns}SAVE_BULK_HISTORIC_TRANSFER_DRAFT_FAILURE`)

export const DeleteHistoricTransferDraft = createAction(`${ns}DELETE_HISTORIC_TRANSFER_DRAFT`)
export const DeleteHistoricTransferDraftSuccess = createAction(`${ns}DELETE_HISTORIC_TRANSFER_DRAFT_SUCCESS`)
export const DeleteHistoricTransferDraftFailure = createAction(`${ns}DELETE_HISTORIC_TRANSFER_DRAFT_FAILURE`)
export const ClearHistoricLedgerImportDraft = createAction(`${ns}CLEAR_HISTORIC_LEDGER_IMPORT_DRAFT`)

export const CreateAdminTransfer = createAction(`${ns}CREATE_ADMIN_TRANSFER`)
export const CreateHolderTransfer = createAction(`${ns}CREATE_HOLDER_TRANSFER`)
export const CreateRetirementTransfer = createAction(`${ns}CREATE_RETIREMENT_TRANSFER`)

export const CreateBulkTransfer = createAction(`${ns}CREATE_BULK_TRANSFER`)
export const CreateBulkTransferSuccess = createAction(`${ns}CREATE_BULK_TRANSFER_SUCCESS`)
export const CreateBulkTransferFailure = createAction(`${ns}CREATE_BULK_TRANSFER_FAILURE`)

export const CancelTransfer = createAction(`${ns}CANCEL_TRANSFER`)

export const SetLoading = createAction(`${ns}SET_LOADING`)
export const SetLoaded = createAction(`${ns}SET_LOADED`)

export const SaveTransferDraft = createAction(`${ns}SAVE_TRANSFER_DRAFT`)
export const ClearTransferDraft = createAction(`${ns}CLEAR_TRANSFER_DRAFT`)
export const ClearTransferDraftSuccess = createAction(`${ns}CLEAR_TRANSFER_DRAFT_SUCCESS`)
export const ClearTransferDraftFailure = createAction(`${ns}CLEAR_TRANSFER_DRAFT_FAILURE`)

/* Go back to edit transfer */
export const EditTransfer = createAction(`${ns}EDIT_TRANSFER`)

export const GetBulkImportDraft = createAction(`${ns}GET_BULK_IMPORT_DRAFT`)
export const GetBulkImportDraftSuccess = createAction(`${ns}GET_BULK_IMPORT_DRAFT_SUCCESS`)
export const GetBulkImportDraftFailure = createAction(`${ns}GET_BULK_IMPORT_DRAFT_FAILURE`)

export const SaveBulkImportDraft = createAction(`${ns}SAVE_BULK_IMPORT_DRAFT`)

export const ClearBulkImportDraft = createAction(`${ns}CLEAR_BULK_IMPORT_DRAFT`)

export const CreateBulkHolder = createAction(`${ns}CREATE_BULK_HOLDER`)
export const CreateBulkHolderSuccess = createAction(`${ns}CREATE_BULK_HOLDER_SUCCESS`)
export const CreateBulkHolderFailure = createAction(`${ns}CREATE_BULK_HOLDER_FAILURE`)

export const BulkImportTransfer = createAction(`${ns}BULK_IMPORT_TRANSFER`)

export const SaveCancelledTransferDraft = createAction(`${ns}SAVE_CANCELLED_TRANSFER_DRAFTS`)
export const ClearCancelledTransferDraft = createAction(`${ns}CLEAR_CANCELLED_TRANSFER_DRAFTS`)

export const AcceptTransfer = createAction(`${ns}ACTIVATE_TRANSFER`)
export const DeclineTransfer = createAction(`${ns}DECLINE_TRANSFER`)
