import { Action, handleActions } from 'redux-actions'
import moment from 'moment'
import * as _ from 'lodash'
import {
  GetTransferDetails,
  GetTransferDetailsSuccess,
  GetTransferList,
  GetTransferListFail,
  GetTransferListSuccess,
  GetTransferorStockList,
  GetTransferorStockListFail,
  GetTransferorStockListSuccess,
  SaveTransferDraft,
  ClearTransferDraft,
  SaveCancelledTransferDraft,
  ClearCancelledTransferDraft,
  SetFilters,
  SetLoaded,
  SetLoading,
  GetTransferDetailsFail,
  GetHistoricTransferDetails,
  GetHistoricTransferDetailsFail,
  GetHistoricTransferDetailsSuccess,
  CreateBulkTransfer,
  CreateBulkTransferSuccess,
  CreateBulkTransferFailure,
  SaveHistoricTransferDraft,
  SaveHistoricTransferDraftSuccess,
  SaveHistoricTransferDraftFailure,
  GetHistoricLedgerImportDraft,
  GetHistoricLedgerImportDraftFailure,
  GetHistoricLedgerImportDraftSuccess,
  GetTransferDraft,
  GetTransferDraftSuccess,
  GetTransferDraftFailure,
  GetCancelledTransferDraft,
  GetCancelledTransferDraftFailure,
  GetCancelledTransferDraftSuccess,
  SaveBulkImportDraft,
  ClearBulkImportDraft,
  GetBulkImportDraft,
  GetBulkImportDraftSuccess,
  GetBulkImportDraftFailure,
  CreateBulkHolderSuccess,
} from './TransfersViewActions'
import {
  AccountStockListDto,
  CreateBulkHolder$Response$Holder,
  GetTransferDetails$Response,
  HolderType,
  MyAccountStockListDto$StockItem,
  TransferListDto,
  UploadedDocumentDto,
} from 'services/api.types'
import { ITransferDraft } from './children/new-transfer-form/NewTransferFormFieldNames'
import { IOption } from 'src/components/ui/form/selectbox/SelectBox'
import { ICancelledTransferDraft } from './children/cancel-transfer-form/CancelTransferFormFieldNames'
import {
  IHistoricTransferDraft,
  ITransferAddedHolder,
} from './children/new-transfer-historic-form/NewTransferHistoricFormFieldNames'
import { SelectUserProfile } from 'common/auth/AuthActions'
import { IBulkImportDraft } from './children/bulk-import-form/BulkImportFormFieldNames'
import { stringTransform } from 'common/utils/stringUtils'

/** Raw decoded draft data shape */
export interface IHistoricLedgerImportDraft {
  transfers: IHistoricTransferDraft[]
  holders: ITransferAddedHolder[]
  data: {
    documents: UploadedDocumentDto[]
  }
}

/** Parsed/remapped draft data shape */
export interface IHistoricLedgerImportRemappedDraft {
  transfers: TransferListDto[]
  holders: AccountStockListDto[]
  data: {
    documents: UploadedDocumentDto[]
  }
}

export const DEFAULT_HISTORIC_IMPORT_DRAFT: IHistoricLedgerImportDraft = {
  transfers: [],
  holders: [],
  data: {
    documents: [],
  },
}

export const DEFAULT_HISTORIC_IMPORT_REMAPPED_DRAFT: IHistoricLedgerImportRemappedDraft = {
  transfers: [],
  holders: [],
  data: {
    documents: [],
  },
}

export interface IFilter {
  searchTerm: string
  stockType: string
}

export const getBlankTransferDraft = (): ITransferDraft => ({
  from: { label: '', value: '' },
  to: { label: '', value: '' },
  stockClassId: { label: '', value: '' },
  numberOfShares: undefined,
  dateOfTransfer: moment().toISOString(),
  amountPaid: undefined,
  comment: '',
  documents: [],
  email: '',
  phoneNumber: '',
  holderType: HolderType.NATURAL_PERSON,
  name: '',
  addedNaturalHolder: null,
  addedLegalHolder: null,
})

class State {
  transfers: TransferListDto[] = []
  transferDetails: GetTransferDetails$Response = {} as GetTransferDetails$Response
  filters: IFilter = { searchTerm: '', stockType: '' }
  newTransferDraft: ITransferDraft = getBlankTransferDraft()
  cancelledTransferDraft: ICancelledTransferDraft = {
    transferId: '',
    documents: [],
    reason: '',
  }
  historicTransfersDraft: IHistoricLedgerImportRemappedDraft = DEFAULT_HISTORIC_IMPORT_REMAPPED_DRAFT
  bulkImportDraftLoading: boolean = false
  bulkImportDraft: IBulkImportDraft = { holders: [], transfers: [] }
  bulkImportHolders: CreateBulkHolder$Response$Holder[]
  historicTransferDetails: IHistoricTransferDraft = null
  loading: boolean = false
  transferDraftLoaded: boolean = false
  transferDraftLoading: boolean = false
  cancelledTransferDraftLoading: boolean = false
  transfersLoading: boolean = false
  transferDetailsLoading: boolean = true
  historicTransferDetailsLoading: boolean = true
  createBulkTrasferLoading: boolean = false
  saveHistoricTransferLoading: boolean = false
  transferorStockList: MyAccountStockListDto$StockItem[] = []
  transferorStockListLoading: boolean = false
  stockTypeOptions: IOption[]
}

const defaultState = new State()

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      filters: defaultState.filters,
      transfers: defaultState.transfers,
      transferorStockList: defaultState.transferorStockList,
      stockTypeOptions: defaultState.stockTypeOptions,
    }),
    [String(GetTransferList)]: (state: State): State => {
      return { ...state, transfersLoading: true }
    },
    [String(GetTransferListSuccess)]: (state: State, action: Action<TransferListDto[]>): State => {
      const options: IOption[] = [{ label: 'ALL', value: '' }]
      action.payload.map(transfer => options.push({ label: transfer.stockClassName, value: transfer.stockClassName }))

      const formattedTransfers: TransferListDto[] = []
      action.payload.map(transfer =>
        formattedTransfers.push({
          ...transfer,
          fromAccountName: stringTransform(transfer.fromAccountName),
          toAccountName: stringTransform(transfer.toAccountName),
        }),
      )

      return {
        ...state,
        stockTypeOptions: _.uniqBy(options, 'value'),
        transfers: formattedTransfers,
        transfersLoading: false,
      }
    },
    [String(GetTransferListFail)]: (state: State): State => {
      return { ...state, transfersLoading: false }
    },
    [String(GetTransferDetails)]: (state: State): State => {
      return { ...state, transferDetailsLoading: true }
    },
    [String(GetTransferDetailsSuccess)]: (state: State, action: Action<GetTransferDetails$Response>): State => {
      return { ...state, transferDetailsLoading: false, transferDetails: action.payload }
    },
    [String(GetTransferDetailsFail)]: (state: State): State => {
      return { ...state, transferDetailsLoading: false }
    },
    [String(GetHistoricTransferDetails)]: (state: State): State => {
      return { ...state, historicTransferDetails: null, historicTransferDetailsLoading: true }
    },
    [String(GetHistoricTransferDetailsSuccess)]: (state: State, action: Action<IHistoricTransferDraft>): State => {
      return { ...state, historicTransferDetailsLoading: false, historicTransferDetails: action.payload }
    },
    [String(GetHistoricTransferDetailsFail)]: (state: State): State => {
      return { ...state, historicTransferDetailsLoading: false }
    },
    [String(SaveHistoricTransferDraft)]: (state: State): State => {
      return { ...state, saveHistoricTransferLoading: true }
    },
    [String(SaveHistoricTransferDraftSuccess)]: (state: State): State => {
      return { ...state, saveHistoricTransferLoading: false }
    },
    [String(SaveHistoricTransferDraftFailure)]: (state: State): State => {
      return { ...state, saveHistoricTransferLoading: false }
    },
    [String(GetHistoricLedgerImportDraft)]: (state: State): State => {
      return { ...state, transfersLoading: true }
    },
    [String(GetHistoricLedgerImportDraftSuccess)]: (
      state: State,
      action: Action<IHistoricLedgerImportRemappedDraft>,
    ): State => {
      return { ...state, transfersLoading: false, historicTransfersDraft: action.payload }
    },
    [String(GetHistoricLedgerImportDraftFailure)]: (state): State => {
      return { ...state, transfersLoading: false }
    },
    [String(CreateBulkTransfer)]: (state: State): State => {
      return { ...state, createBulkTrasferLoading: true }
    },
    [String(CreateBulkTransferSuccess)]: (state: State): State => {
      return { ...state, createBulkTrasferLoading: false }
    },
    [String(CreateBulkTransferFailure)]: (state: State): State => {
      return { ...state, createBulkTrasferLoading: false }
    },
    [String(SetFilters)]: (state: State, action: Action<IFilter>): State => {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    },
    [String(SaveTransferDraft)]: (state: State, action: Action<ITransferDraft>): State => {
      return { ...state, newTransferDraft: action.payload }
    },
    [String(ClearTransferDraft)]: (state: State): State => {
      return { ...state, newTransferDraft: defaultState.newTransferDraft }
    },
    [String(SaveCancelledTransferDraft)]: (state: State, action: Action<ICancelledTransferDraft>): State => {
      return { ...state, cancelledTransferDraft: action.payload || defaultState.cancelledTransferDraft }
    },
    [String(ClearCancelledTransferDraft)]: (state: State): State => {
      return { ...state, cancelledTransferDraft: defaultState.cancelledTransferDraft }
    },
    [String(GetBulkImportDraft)]: (state: State): State => {
      return { ...state, bulkImportDraft: defaultState.bulkImportDraft, bulkImportDraftLoading: true }
    },
    [String(GetBulkImportDraftSuccess)]: (state: State, action: Action<IBulkImportDraft>): State => {
      return {
        ...state,
        bulkImportDraft: action.payload ?? defaultState.bulkImportDraft,
        bulkImportDraftLoading: false,
      }
    },
    [String(GetBulkImportDraftFailure)]: (state: State, action: Action<IBulkImportDraft>): State => {
      return { ...state, bulkImportDraftLoading: false }
    },
    [String(SaveBulkImportDraft)]: (state: State, action: Action<IBulkImportDraft>): State => {
      return { ...state, bulkImportDraft: action.payload }
    },
    [String(ClearBulkImportDraft)]: (state: State): State => {
      return { ...state, bulkImportDraft: defaultState.bulkImportDraft }
    },
    [String(CreateBulkHolderSuccess)]: (state: State, action: Action<CreateBulkHolder$Response$Holder[]>): State => {
      return { ...state, bulkImportHolders: action.payload }
    },
    [String(SetLoading)]: (state: State): State => {
      return { ...state, loading: true }
    },
    [String(SetLoaded)]: (state: State): State => {
      return { ...state, loading: false }
    },
    [String(GetTransferorStockList)]: (state: State): State => {
      return { ...state, transferorStockListLoading: true }
    },
    [String(GetTransferorStockListSuccess)]: (
      state: State,
      action: Action<MyAccountStockListDto$StockItem[]>,
    ): State => {
      return { ...state, transferorStockList: action.payload, transferorStockListLoading: false }
    },
    [String(GetTransferorStockListFail)]: (state: State): State => {
      return { ...state, transferorStockListLoading: false }
    },
    [String(GetTransferDraft)]: (state: State): State => {
      return {
        ...state,
        newTransferDraft: defaultState.newTransferDraft,
        transferDraftLoading: true,
        transferDraftLoaded: false,
      }
    },
    [String(GetTransferDraftSuccess)]: (state: State, action: Action<ITransferDraft>): State => {
      return {
        ...state,
        newTransferDraft: action.payload || defaultState.newTransferDraft,
        transferDraftLoading: false,
        transferDraftLoaded: true,
      }
    },
    [String(GetTransferDraftFailure)]: (state: State): State => {
      return { ...state, transferDraftLoaded: false, transferDraftLoading: false }
    },
    [String(GetCancelledTransferDraft)]: (state: State): State => {
      return { ...state, cancelledTransferDraftLoading: true }
    },
    [String(GetCancelledTransferDraftSuccess)]: (state: State, action: Action<ICancelledTransferDraft>): State => {
      return { ...state, cancelledTransferDraft: action.payload, cancelledTransferDraftLoading: false }
    },
    [String(GetCancelledTransferDraftFailure)]: (state: State): State => {
      return { ...state, cancelledTransferDraftLoading: false }
    },
  },
  new State(),
)

export { State as TransfersViewState }
