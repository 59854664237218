import * as Yup from 'yup'
import moment from 'moment'
import { UploadedDocumentDto } from 'services/api.types'

// default validation messages
Yup.setLocale({
  mixed: {
    default: 'common:errors.field-invalid',
    required: 'common:errors.field-required',
  },
  string: {
    default: 'common:errors.field-invalid',
    required: 'common:errors.field-required',
  },
  number: {
    default: 'common:errors.field-invalid',
    required: 'common:errors.field-required',
  },
  array: {
    default: 'common:errors.field-invalid',
    required: 'common:errors.field-required',
  },
})

/*
    Predefined VALIDATIONS
 */

const STREET_ADDRESS = Yup.string()
  .strict(true)
  .trim('common:errors.address.invalid')
  .min(1, 'common:errors.address.min')
  .matches(/^[^\[\]{}?!@%|><¤=]*$/i, { message: 'common:errors.address.invalid', excludeEmptyString: true })
  .ensure()

const BUILDING_NUMBER = Yup.string()
  .strict(true)
  .trim('common:errors.address.invalid')
  .min(1, 'common:errors.address.min')
  .matches(/^[^\[\]{}?!@%|><¤=]*$/i, { message: 'common:errors.address.invalid', excludeEmptyString: true })

const APARTMENT = Yup.string()
  .strict(true)
  .trim('common:errors.address.invalid')
  .min(1, 'common:errors.address.min')
  .matches(/^[^\[\]{}?!@%|><¤=]*$/i, { message: 'common:errors.address.invalid', excludeEmptyString: true })

const CITY = Yup.string().strict(true).trim('common:errors.address.invalid').min(3, 'common:errors.city').ensure()

const STATE = Yup.string()

const ZIP = Yup.string()
  .strict(true)
  .trim('common:errors.zip.invalid')
  .min(2, 'common:errors.zip.min')
  .max(20, 'common:errors.zip.max')
  .matches(/^[A-Za-z0-9-_ ]+$/, { message: 'common:errors.zip.invalid' })
  .ensure()

const ZIP_USA = Yup.string()
  .strict(true)
  .trim('common:errors.zip.invalid')
  .min(5, 'common:errors.zip.usa.min')
  .max(5, 'common:errors.zip.usa.max')
  .matches(/^\d+$/, { message: 'common:errors.zip.invalid' })
  .ensure()

const COUNTRY = Yup.string().min(2, 'common:errors.country').ensure()

const NUMBER = Yup.string().matches(/^([0-9]|\+)+$/, 'common:errors.number')
const TIN = Yup.string().matches(/^([a-z0-9]|\+)+$/i, 'common:errors.tin') // Taxpayer Identification Number
const EMAIL = Yup.string().email('common:errors.email')
const GIVEN_NAMES = Yup.string()
  .strict(true)
  .trim('common:errors.given-names.invalid-chars')
  .matches(/^[^\[\]{}()?!@%|><¤#=]*$|([^ \t\r\n])[ \t]+$/i, {
    message: 'common:errors.given-names.invalid-chars',
    excludeEmptyString: true,
  })
  .min(2, 'common:errors.given-names.min')
const LAST_NAME = Yup.string()
  .strict(true)
  .trim('common:errors.last-name.invalid-chars')
  .matches(/^[^\[\]{}()?!@%|><¤#=]*$/i, {
    message: 'common:errors.last-name.invalid-chars',
    excludeEmptyString: true,
  })
  .min(2, 'common:errors.last-name.min')
const MIDDLE_NAME = Yup.string()
  .strict(true)
  .trim('common:errors.middle-name.invalid-chars')
  .matches(/^[^\[\]{}()?!@%|><¤#=]*$/i, {
    message: 'common:errors.middle-name.invalid-chars',
    excludeEmptyString: true,
  })
  .min(1, 'common:errors.middle-name.min')
const NAME_SUFFIX = Yup.string()
  .strict(true)
  .trim('common:errors.name-suffix.invalid-chars')
  .matches(/^[^\[\]{}()?!@%|><¤#=]*$/i, {
    message: 'common:errors.name-suffix.invalid-chars',
    excludeEmptyString: true,
  })
  .min(1, 'common:errors.name-suffix.min')
const PHONE_NUMBER = Yup.string()
  .max(15, 'common:errors.phone-number.max')
  .matches(/^([0-9]|\+)+$/, 'common:errors.phone-number.invalid')
const BIRTH_DAY = Yup.number().min(1).max(31)
const BIRTH_YEAR = Yup.number().min(moment().subtract(120, 'years').year()).max(moment().subtract(18, 'years').year())
const PASSWORD = Yup.string().min(7, 'common:errors.password')
const COMPANY_NAME = Yup.string().min(2, 'common:errors.company-name')
const COMPANY_FILE_NUMBER = Yup.string().matches(/^\d{7}$/, 'common:errors.company-file-number')
const SSN = Yup.string().matches(/^\d{9}$/, 'common:errors.ssn')
const FILES = Yup.array<UploadedDocumentDto>()
const LOCAL_FILE = Yup.object({
  name: Yup.string().required(),
})
const ADDRESS = Yup.string()
  .min(1, 'common:errors.address.min')
  .ensure()
  .matches(/^[^\[\]{}?!@%|><¤=]*$/i, { message: 'common:errors.address.invalid', excludeEmptyString: true })
const ADDRESS_OBJECT_REQUIRED = Yup.object({
  country: COUNTRY.required(),
  zipCode: ZIP.required('common:errors.address.invalid').when('country', {
    is: 'United States of America',
    then: ZIP_USA.required(),
  }),
  state: STATE.when('country', {
    is: 'United States of America',
    then: STATE.min(2, 'common:errors.state').ensure(),
  }),
  city: CITY.required(),
  streetAddress: STREET_ADDRESS.required(),
  buildingNumber: BUILDING_NUMBER,
  apartment: APARTMENT,
})
const ADDRESS_OBJECT = Yup.object({
  country: COUNTRY,
  zipCode: ZIP,
  state: STATE,
  city: CITY,
  streetAddress: STREET_ADDRESS,
  buildingNumber: BUILDING_NUMBER,
  apartment: APARTMENT,
})

const APP_KEY = Yup.string()
  .matches(/^\d+$/, { message: 'common:errors.app-key.invalid' })
  .min(5, 'common:errors.app-key.min')
const SMS_CODE = Yup.string()
  .matches(/^\d+$/, { message: 'common:errors.sms-code.invalid' })
  .min(6, 'common:errors.sms-code.min')
const AGE = Yup.date()
  .min(moment().subtract(120, 'years'), 'common:errors.age.min')
  .max(moment().subtract(18, 'years'), 'common:errors.age.max')

const VALIDATIONS = {
  AGE,
  COMPANY_FILE_NUMBER,
  ADDRESS,
  ADDRESS_OBJECT_REQUIRED,
  ADDRESS_OBJECT,
  APARTMENT,
  STATE,
  COUNTRY,
  CITY,
  BUILDING_NUMBER,
  ZIP,
  ZIP_USA,
  FILES,
  LOCAL_FILE,
  COMPANY_NAME,
  EMAIL,
  GIVEN_NAMES,
  LAST_NAME,
  MIDDLE_NAME,
  NAME_SUFFIX,
  PHONE_NUMBER,
  PASSWORD,
  BIRTH_DAY,
  BIRTH_YEAR,
  NUMBER,
  SSN,
  SMS_CODE,
  APP_KEY,
  TIN,
}

export default VALIDATIONS
