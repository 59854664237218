import LS from 'common/utils/localStorage'
import { storeAndPersistor } from '../../index'
import { sessionExpired } from './AuthActions'
import { SESSION_EXPIRES } from 'common/constants/localstorage-keys'

class SessionHelper {
  timeoutInSeconds: number
  _expiryTimeout: number

  constructor() {
    this.timeoutInSeconds = +process.env.REACT_APP_SESSION_TIMEOUT_SECONDS
    this._expiryTimeout = null
  }

  get expiry() {
    return LS.getData<number>(SESSION_EXPIRES)
  }

  setExpiry() {
    const now = Math.round(new Date().getTime() / 1000.0) // round it to nearest second
    const expiryTimestampInSeconds = now + this.timeoutInSeconds

    LS.put(SESSION_EXPIRES, expiryTimestampInSeconds)

    return expiryTimestampInSeconds
  }

  removeExpiry() {
    localStorage.removeItem(SESSION_EXPIRES)
  }

  get isTokenExpired() {
    const now = Math.round(new Date().getTime() / 1000.0)
    const expiryTimestamp = this.expiry
    const isTokenExpired = expiryTimestamp !== null && now > expiryTimestamp
    return isTokenExpired
  }

  get isTokenExpiredOrNull() {
    const isExpiryNull = this.expiry === null // means the user had an other tab open which triggered the timeout already
    return this.isTokenExpired || isExpiryNull
  }

  /* expiry timeout */
  get expiryTimeout() {
    if (this._expiryTimeout !== null) {
      // return the remaining time until timer has finished
      const now = Math.round(new Date().getTime() / 1000.0)
      const expiryTimestamp = this.expiry
      if (expiryTimestamp === null) {
        return null
      }
      const timeTillExpiryInSeconds = expiryTimestamp - now
      return timeTillExpiryInSeconds
    }
    return null
  }

  startExpiryTimeout() {
    this._expiryTimeout = window.setTimeout(() => {
      if (this.isTokenExpiredOrNull) {
        this.removeExpiry()
        storeAndPersistor.store.dispatch({
          type: String(sessionExpired),
        })
      } else {
        this.startExpiryTimeout()
      }
    }, this.timeoutInSeconds * 1000 + 1000) // add 1 second to counter rounding errors
  }

  stopExpiryTimeout() {
    clearTimeout(this.expiryTimeout)
  }

  resetExpiryTimeout() {
    this.stopExpiryTimeout()
    this.startExpiryTimeout()
  }
}

const instance = new SessionHelper()
export default instance
