import { all } from 'redux-saga/effects'

import authSagas from 'common/auth/AuthSagas'
import routerSagas from 'common/router/RouterSaga'
import stockSaga from 'common/store/stocks/stocksSaga'
import commonSaga from 'common/store/common/commonSaga'
import loginViewSagas from 'views/login/LoginViewSagas'
import transferViewSagas from 'views/transfers/TransfersViewSagas'
import issuanceViewSagas from 'views/issuance/IssuanceViewSagas'
import holderListViewSagas from 'views/holder-list/HolderListViewSagas'
import votingListViewSagas from 'views/voting-list/VotingListViewSagas'
import verificationViewSagas from 'views/verification/VerificationViewSagas'
import SigningViewSagas from 'views/signing/SigningViewSagas'
import settingsViewSagas from 'views/settings/SettingsViewSagas'
import credentialsViewSagas from 'views/settings/children/credentials/CredentialsViewSagas'
import userFormSagas from 'views/settings/children/user/UserFormSagas'
import LegalEntityFormSagas from 'views/settings/children/legal-entity/LegalEntityFormSagas'
import companyFormSagas from 'views/settings/children/company/CompanyFormSagas'
import stockFormSagas from 'views/settings/children/stock/StockFormSagas'
import companyHistorySagas from 'views/settings/children/company-history/CompanyHistory.sagas'
import ledgerPreferencesSagas from 'views/settings/children/ledger-preferences/LedgerPreferences.sagas'
import dashboardViewSagas from 'views/dashboard/DashboardViewSagas'
import forgotPasswordViewSagas from 'views/forgot-password/ForgotPasswordViewSagas'
import recoverySmsViewSagas from 'views/recovery-sms/RecoverySmsViewSagas'
import registrationViewSagas from 'views/registration/RegistrationViewSagas'

export default function* rootSaga() {
  yield all([
    authSagas(),
    routerSagas(),
    stockSaga(),
    commonSaga(),
    verificationViewSagas(),
    SigningViewSagas(),
    dashboardViewSagas(),
    settingsViewSagas(),
    credentialsViewSagas(),
    loginViewSagas(),
    transferViewSagas(),
    issuanceViewSagas(),
    holderListViewSagas(),
    votingListViewSagas(),
    userFormSagas(),
    LegalEntityFormSagas(),
    companyFormSagas(),
    stockFormSagas(),
    forgotPasswordViewSagas(),
    registrationViewSagas(),
    recoverySmsViewSagas(),
    companyHistorySagas(),
    ledgerPreferencesSagas(),
  ])
}
