import { Action, handleActions } from 'redux-actions'
import {
  AddAndTriggerPhoneVerification,
  AddRegisterNotification,
  ClearRegisterNotification,
  PhoneVerificationCodeFail,
  UpdateAndReTriggerPhoneVerification,
  UpdatePhoneVerificationCode,
  SetPhoneVerificationSent,
} from './RegistrationViewActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { IAddPhoneField } from './children/phone/AddPhoneFieldNames'
import { AddCredential$Request, ChangeCredential$Request } from 'services/api.types'

class State {
  phoneVerificationCode: string = ''
  phoneVerificationCodeFail: boolean = false
  phoneVerificationSent: boolean = false
  notification: INotification = null
  addPhoneFields: IAddPhoneField = null

  constructor(props?: State) {
    if (props) {
      this.phoneVerificationCode = props.phoneVerificationCode || ''
      this.phoneVerificationCodeFail = false
      this.phoneVerificationSent = false
      this.notification = props.notification || null
      this.addPhoneFields = props.addPhoneFields || null
    }
  }
}

export default handleActions<State, any>(
  {
    [String(AddAndTriggerPhoneVerification)]: (state: State, action: Action<AddCredential$Request>): State => {
      return {
        ...state,
        phoneVerificationCodeFail: false,
        phoneVerificationSent: true,
        addPhoneFields: { ...state.addPhoneFields, phoneNumber: action.payload.value },
      }
    },
    [String(UpdateAndReTriggerPhoneVerification)]: (state: State, action: Action<ChangeCredential$Request>): State => {
      return {
        ...state,
        phoneVerificationCodeFail: false,
        phoneVerificationSent: true,
        addPhoneFields: { ...state.addPhoneFields, phoneNumber: action.payload.value },
      }
    },
    [String(SetPhoneVerificationSent)]: (state: State, action: Action<boolean>): State => {
      return {
        ...state,
        phoneVerificationSent: action.payload,
      }
    },
    [String(UpdatePhoneVerificationCode)]: (state: State, action: Action<IAddPhoneField>): State => {
      return {
        ...state,
        phoneVerificationCodeFail: false,
        phoneVerificationSent: true,
        addPhoneFields: action.payload,
      }
    },
    [String(PhoneVerificationCodeFail)]: (state: State): State => {
      return { ...state, phoneVerificationCodeFail: true, phoneVerificationSent: true }
    },
    [String(AddRegisterNotification)]: (state: State, action: Action<INotification>): State => {
      return {
        ...state,
        notification: action.payload,
      }
    },
    [String(ClearRegisterNotification)]: (state: State): State => {
      return {
        ...state,
        notification: null,
      }
    },
  },
  new State(),
)

export { State as RegistrationViewState }
