import { createAction } from 'redux-actions'

const ns = 'registration/'

export const AddRegisterNotification = createAction(`${ns}ADD_REGISTER_NOTIFICATION`)
export const ClearRegisterNotification = createAction(`${ns}CLEAR_REGISTER_NOTIFICATION`)

export const CreatePassword = createAction(`${ns}CREATE_PASSWORD`)

export const AddAndTriggerPhoneVerification = createAction(`${ns}ADD_AND_TRIGGER_PHONE_VERIFICATION`)
export const UpdateAndReTriggerPhoneVerification = createAction(`${ns}UPDATE_AND_RE_TRIGGER_PHONE_VERIFICATION`)
export const UpdatePhoneVerificationCode = createAction(`${ns}UPDATE_PHONE_VERIFICATION_CODE`)
export const PhoneVerificationCodeFail = createAction(`${ns}PHONE_VERIFICATION_CODE_FAIL`)
export const SetPhoneVerificationSent = createAction(`${ns}SEt_PHONE_VERIFICATION_SENT`)

export const SendAppDownloadInfo = createAction(`${ns}SEND_APP_DOWNLOAD_INFO_SMS`)
export const AddAndTriggerAppKeyVerification = createAction(`${ns}ADD_APP_AND_TRIGGER_VERIFICATION`)
export const UpdateAndTriggerAppKeyVerification = createAction(`${ns}UPDATE_APP_AND_TRIGGER_VERIFICATION`)
